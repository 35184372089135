#mn-admin-side {
    background-color: white;
    height: 100%;
    color: gray;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    position: relative;


    .expand {
        width: 3vw;
        height: 3vw;
        min-width: 30px;
        min-height: 30px;
        position: absolute;
        right: -20px;
        top: 1rem;
        z-index: 99;
        fill: rgba(#595bb2, 0.8);
        background-color: white;
        box-shadow: 0px 0px 10px lightgray;
        border-radius: 50%;
        cursor: pointer;
        transform: rotatez(-180deg);
        transition: transform 200ms ease-in-out;

        &:hover {
            fill: var(--primary-color);
        }

    }


    ul {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        width: 100%;
        height: 100%;

        hr {
            padding: 0;
            margin: 0;
        }
        li {
            list-style: none;
            width: 100%;
            border-left: 6px solid transparent;
            padding: 15px;

            &:hover {
                background-color: var(--clr-gray150);
            }
    
            a {
                color: var(--clr-gray400);
                display: flex;
                gap: 1rem;
                align-items: center;
                

                svg {
                    fill: var(--clr-gray400);
                    width: 2rem;
                    height: 2rem;
                }

                p {
                    margin: 0;
                    white-space: nowrap;
                }
            }
            
            &.active {
                background-color: var(--clr-gray200);
                border-left: 6px solid var(--primary-color);

                a {
                    color: var(--primary-color);
                    svg {
                        fill: var(--primary-color);
                    }
                }
                
            }
        }
    }

    &.closed {
        .expand {
            transform: rotatez(0deg);
        }

        ul {
            p {
                display: none;
            }
        }
    }
    
}

