#mn-stream-banner {
    position: relative;

    .bg {
        display: block;
        width: 100%;
        height: 552px;
        animation: fadeIn 0.3s forwards;
        background-image: url("./images/bg.jpg");
        background-size: cover;
        cursor: pointer;
        z-index: -1;

        position: absolute;
        top: 0;
        left: 0;
    }

    .header-placehoder {
        height: 80px;
        visibility: hidden;
    }

    .slider-wrapper {
        width: 960px;
        border-radius: var(--border-radius);
        background-color: rgba(0,0,0,.8);
        overflow: hidden;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;


        .slider-player {

        }

        .slider-tabs {
            img {
                width: 100%;
            }
        }
    }
}