#mn-stream-fix-sidebar {
    width: 72px;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    position: fixed;
    right: 8px;
    top: 140px;
    padding: 24px 0;
    z-index: 9999;
    text-align: center;

    .download-container {
        display: none;

        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-100%)
                   translateY(-30%);
        background-color: var(--primary-color);
        border-radius: calc(2 * var(--border-radius));




        .wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            font-size: 14px;
            font-weight: bold;
            color: white;

            .top {
                width: 126px;
                height: 102px;
            }

            .down {
                width: 154px;
                height: 34px;
                background: #fff;
                border-radius: var(--border-radius);
                color: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        
    }

    .item  {
        img {
            width: 60%;
            height: 60%;
        }

        p {
            font-size: 12px;
            color: var(--primary-color);
        }

        &:nth-child(2){
            position: relative;
        }
        &:nth-child(2):hover .download-container {
            display: block;
        }
    }
}