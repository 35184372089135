#mn-practice-rmcs {
    .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        justify-content: start;
        
        .left {
        }
        .right {
            .top {
                
                .btn.btn-primary {
                    width: 25%;
                }
            }
            
        }
    }
}