#mn-course {
    background-color: #f4f7fa;

    .tab-content {
        color: white;

        .btn {
            position: absolute;
            bottom: 5%;
            left: 5%;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 50px;
        }
    }

 
    .nav-item{
        .nav-link {
            color: black;
        }
        .nav-link.active {
            color: white;
            background-color: var(--primary-color);
        }
        
    }

  
}