

#mn-practice-list{
    $speak: "#ff698e";
    $write: "#f58a50";
    $read: "#ff698e";
    $listen: "#ff698e";
    width: 100%;
    
    .practice-panel {

        .title {
            border-radius: var(--border-radius);
            text-align: center;
            background-color: var(--primary-color);
            color: white;
            font-size: 20px;
        }
        .exercises {
            ul {
                background-color: var(--shallow-bg-color);
                border-radius: var(--border-radius);

                .exercises-list {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;

                    p {
                        width: 56px;
                        text-align: center;
                        margin: 0;

                        span {
                            display: block;
                            font-size: 18px;
                            font-weight: Bold;
                            letter-spacing: 0px;
                            color: #ccccff;
                            margin-bottom: 15px;
                        }

                        i {
                            display: block;
                            font-style: normal;
                            font-size: 14px;
                            letter-spacing: 0px;
                            color: #333333;
                        }
                        
                        img {
                            width: 56px;
                            height: 56px;
                        }
                    }

                    .list-tab {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 1rem;
                        width: 100%;

                        a {
                            border-radius: var(--border-radius);
                            box-shadow: var(--box-shadow);
                            background-color: white;
                            padding: 10px;
                            border: 3px solid white;
                            display: grid;
                            grid-template-columns: 1fr 8fr;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 1rem;
                            color: black;

                            &:hover {
                                border: 3px solid var(--primary-color);
                                color: var(--primary-color);
                            }

                            span {
                                color: var(--primary-color);
                                border: 1px solid var(--primary-color);
                                border-radius: var(--border-radius);
                                text-align: center;
                            }


                        }
                    }


                }



            }
        }
    }
}