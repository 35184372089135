.mk-list {
    background-color: #ffffff;
    border-radius: 5px;
    position: absolute;
    top: 80px;
    left: -140px;
    z-index: 101;
    box-shadow: -1px -2px 12px 0px rgba(89, 91, 178, 0.3);
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    transform: scale(1);
    pointer-events: none;
    visibility: hidden;
}

.mk-list:before {
    position: absolute;
    z-index: -5;
    bottom: 100%;
    left: 45%;
    display: block;
    width: 16px;
    height: 16px;
    content: '';
    transform: rotate(-45deg) translateY(1rem);
    border-radius: .2rem;
    background: #fff;
    box-shadow: none;
}

.mock-hover:hover .mk-list {
    transform: scale(1.1);
    pointer-events: auto;
    visibility: visible;
    transition: all 0.3s;
}

.mk-list-item {
    text-align: center;
    width: 120px;
    margin: 10px 0 10px 8px;
    padding: 0 0 0 5px;
    line-height: 36px;
    border-left: 1px solid #e5e5e5;

    h5 {
        color: #333333;
        margin-left: 8px;
        font-size: 16px;
    }
    

    p {
        font-size: 14px;
        cursor: pointer;
        line-height: 32px;
        border-radius: 3px;
        border: solid 1px rgba(255, 255, 255, 0);
    
        a {
            font-size: 14px;
        }
    
        .mk-list-item p:hover {
            border: solid 1px #470fa8;
            font-weight: bold;
        }
    }
}

.mk-list-item:first-child {
    border-left: none;
}

.mk-list-item:last-child {
    margin-right: 10px;
}
