#mn-testimony {
    img {
        width: 100%;
    }


    .swiper-button-prev, 
    .swiper-button-next {
        width: 4.5vw;
        height: 4.5vw;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;
        margin: auto;
        pointer-events: auto;
        cursor: pointer;
        background-image: url("./images/arrow.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &:after {
            display: none;
        }
        
        &:hover {
            background-image: url("./images/arrow_hover.png");
        }
    }


    .swiper-button-prev {
        left: 5vw;
    }
    .swiper-button-next {
        transform: scaleX(-1);
        right: 5vw;
    }
    
      
    
}