.modal {
    &.right {
        #mn-modal-question-list {
            
            &.modal-dialog {
                width: 100%;
                position: fixed;
                top: 0;
                right: 0;
                margin: auto;
                height: 100%;
                overflow: scroll;
                transform: translate3d(0%, 0, 0);
                transition: opacity 0.3s linear, right 0.3s ease-out;


                .modal-title {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;

                    form {
                        $blue: #008ABF;

                        position: relative;
                        input{
                            margin: 0 auto;
                            width: 100%;
                            height: 45px;
                            padding: 0 20px;
                            font-size: 1rem;
                            border: 1px solid #D0CFCE;
                            border-radius: var(--border-radius);
                            outline: none;
                            &:focus{
                              border: 1px solid $blue;
                              transition: 0.35s ease;
                              color: $blue;
                              &::-webkit-input-placeholder{
                                transition: opacity 0.45s ease; 
                                  opacity: 0;
                               }
                              &::-moz-placeholder {
                                transition: opacity 0.45s ease; 
                                  opacity: 0;
                               }
                              &:-ms-placeholder {
                               transition: opacity 0.45s ease; 
                                 opacity: 0;
                               }    
                             }
                        }
    
                        svg {
                            position: absolute;
                            width: 35px;
                            height: 35px;
                            right: 0;
                            top: 15%;
                            cursor: pointer;
                            color: $blue;
                        }
                    }
                    
                }

                .question-filter {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                }

                .modal-content {
                    height: 100%;
                    overflow-y: scroll;
                }


                .title-icon {
                    background-color: var(--primary-color);
                    color: white;
                    border-radius: var(--border-radius);
                }


                .list-group-item {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                    justify-content: space-between;
                    align-items: center;

                    &.active {
                        background-color: #e6effa;
                        border-color: transparent;
                        color: var(--primary-color);
                    }

                    .left, .right {
                        display: flex;
                        flex-direction: row;
                        gap: 1rem;
                        align-items: center;
                        
                    }

                    svg {
                        width: 1.5rem;
                        height: 1.5rem; 
                    }
                }

                .bottom {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2rem;

                    .pagination {
                        margin: 0;
                    }

                    input {
                        width: 50px;
                        height: 35px;
                        border-radius: var(--border-radius);
                        border: 1px solid #D0CFCE;
                    }
                }

                .tag {
                    height: 22px;
                    line-height: 22px;
                    border-radius: 3px;
                    font-size: 12px;
                    padding: 0 8px;

                    &-red {
                        border:1px solid red;
                        color: red;
                    }
                    &-blue {
                        border:1px solid blue;
                        color: blue;
                    }
                    &-green{
                        border:1px solid green;
                        color: green;
                    }
                    &-orange {
                        border:1px solid orange;
                        color: orange;
                    }
                    &-purple {
                        border: 1px solid purple;
                        color: purple;
                    }
                    &-burlywood {
                        border: 1px solid burlywood;
                        color: burlywood;
                    }
                }
            }
        }
    }
}
    
