#mn-compare {
    .main {
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
        margin: 0 auto;
        width: 1200px;
        font-size: 1.2vw;
        font-weight: bold;

        .price-table {
            width: 100%;
            border-collapse: collapse;
            border: 0 none;

            tr:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.03);
            }

            tr td {
                border-left: 1px solid rgba(0, 0, 0, 0.05);
                padding: 0.5vw 1.2vw;
            }

            tr td:first-child {
                border-left: 0 none;
            }
            tr td:not(:first-child) {
                text-align: center;
            }

            tr:not(:first-child):hover {
                background-color: #EEEEEE;
            }

            tr.table-head {
                background-color: var(--primary-color);
                color: hsl(0, 0%, 100%);
            }
            tr.table-head td {
                font-weight: 600;
                text-transform: uppercase;
            }



            tr:nth-child(1) {
                font-size: 2vw;
            }
            /* Highlighted column */
            /* head */
            tr:nth-child(1) td:nth-child(1) {
                background-color: var(--shallow-color);
            }
            tr:nth-child(1) td:nth-child(3) {
                background-color: var(--shallow-color);
                color: var(--primary-color);
            }
            /* body */
            tr td:nth-child(1) {
                background-color: rgba(216, 214, 227, 0.15);
                padding: 0.5vw 1.8vw;
            }
            tr td:nth-child(2) {
                background-color: var(--primary-color);
                padding: 0.5vw 1.8vw;
                color: white;
            }
            tr td:nth-child(3) {
                background-color: rgba(216, 214, 227, 0.15);
                padding: 0.5vw 1.8vw;
            }

            /* column width */
            tr td:nth-child(1) {
                width: 18%;
            }
        }
    }

   
}



