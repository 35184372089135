#mn-admin-page {
    width: 100vw;
    height: 100vh;  
    
    main {
        position: absolute;
        width: 100%;
        top: 80px;
        left: 0px;
        height: calc(100vh - 80px);
        display: flex;
        flex-direction: row;
        gap: 30px;
        background-color: var(--clr-gray100);
    }
}