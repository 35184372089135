#mn-modal-aist {
    


    .box {
        width: 100%;
        height: 100%;
        background: url(https://upload.fireflyau.com/web_gwpte/pte/vip/scoring-coupon-bg.png) 0px 0px no-repeat;
        background-position: center top;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .content {
            width: 100%; 
            height: 60%; 
            margin: 0 auto;
            background-color: rgb(255, 255, 255); 
            box-shadow: rgba(162, 162, 162, 0.14) 0px 7px 10px 0px; 
            border-radius: 10px; 
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: gray;

           
            h3 {
                font-weight: bold;
                letter-spacing: -1px;
                color: rgb(66, 66, 66);
            }

            i {
                font-size: 60px;
                color: var(--primary-color);
                margin: 0px 20px;
            }
          
        }

        .buttons {
            display: flex;
            justify-content: space-around;
            font-size: 16px;

            span:nth-child(1) {
                line-height: 46px;
                background-image: linear-gradient(90deg, rgb(35, 204, 209), rgb(26, 208, 179)), linear-gradient(rgb(114, 153, 254), rgb(114, 153, 254));
                border-radius: 23px;
                color: rgb(255, 255, 255);
            }
            span:nth-child(2) {
                line-height: 42px;
                background-color: rgb(255, 255, 255);
                border-radius: 23px;
                border: 2px solid var(--primary-color);
                color: var(--primary-color);
            }
        }
    }
   
}