#mn-practice-rmcm {
    .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        justify-content: start;
        
        .left {
        }
        .right {

            .btn.btn-primary {
                width: 25%;
            }
        }
    }
}