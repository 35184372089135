#mn-practice-header{ 
    width: 100%;
    background-color: var(--primary-color);
    color: white;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    align-items: center;

    border-radius: var(--border-radius);

    svg {
        color: white;
        width: 20px;
        height: 20px;
    }
    .left {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 2vw;

        &> div {
            cursor: pointer;
            border-bottom: 2px solid var(--primary-color);
        }
        &> div:not(:first-child):hover {
            border-bottom: 2px solid #fff;
        }
        &> div:not(:first-child).active {
            border-bottom: 2px solid #fff;
        }

        .type {
            display: flex;
            align-items: center;
        }
    }
}

@media only screen and (max-width: 960px) {
    #mn-practice-header {
        font-size: 2vw;
    }
}