#mn-image-uploader {

    &.file-upload {
        background-color: #ffffff;
        width: 600px;
        margin: 0 auto;
        padding: 20px;
    }
      
    .file-upload-btn {
        width: 100%;
        margin: 0;
        color: #fff;
        background: #1FB264;
        border: none;
        padding: 10px;
        border-radius: 4px;
        border-bottom: 4px solid #15824B;
        transition: all .2s ease;
        outline: none;
        text-transform: uppercase;
        font-weight: 700;
    }
      
    .file-upload-btn:hover {
        background: #1AA059;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }
      
    .file-upload-btn:active {
        border: 0;
        transition: all .2s ease;
    }
      
    .file-upload-content {
        text-align: center;
    }
      
    .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
    }
      
    .image-upload-wrap {
        margin-top: 20px;
        border: 4px dashed #1FB264;
        position: relative;
    }
      
    .image-dropping,
    .image-upload-wrap:hover {
        background-color: #dadada;
        border: 4px dashed #ffffff;
    }
      
    .image-title-wrap {
        padding: 0 15px 15px 15px;
        color: #222;
    }
      
    .drag-text {
        text-align: center;
    }
      
    .drag-text h3 {
        font-weight: 100;
        color: #15824B;
        padding: 60px 0;
    }
      
    .file-upload-image {
        max-height: 200px;
        max-width: 200px;
        margin: auto;
        padding: 20px;
    }
      
    .remove-image {
        width: 200px;
        margin: 0;
        color: #fff;
        background: #cd4535;
        border: none;
        padding: 10px;
        border-radius: 4px;
        border-bottom: 4px solid #b02818;
        transition: all .2s ease;
        outline: none;
        font-weight: 700;
    }
      
    .remove-image:hover {
        background: #c13b2a;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }
      
    .remove-image:active {
        border: 0;
        transition: all .2s ease;
    }
}


div.alert {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}