.audioIndicator {
    $green: #6ABB5C;
    $blue: #4FABE4;
    $white: #fff;

    border-style: solid;
    border-width: thin;
    border-color: #99aabf;
    background-color: #e7effa;
    width: 300px;
    margin: 0 auto;

    td {
        padding-left: 5px;
        padding-right: 5px;

        .btn {
            transition: all 0.4s ease-in-out;
            background: #ffffff;
            border: 1px solid #E5E5E5;
            border-radius: 0;
            outline: none;
            color: #B5B4B9;


            &:hover {
                background: $green;
                border-color: darken($green, 5);
                color: $white;
              }
        }
    }

    .processbar {
        height: 20px;

        &__prepare {
            background-color: lightgray;
        }

        &__practice {
            background-color: #6699ff;
        }
    }

}