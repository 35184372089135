#mn-product {
    max-width: 100%;
    overflow: hidden;
    color: white;


    background-color:orange;
    background-attachment: fixed;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1053%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(247%2c 117%2c 2%2c 1)'%3e%3c/rect%3e%3cpath d='M980.09 497.32 a177.48 177.48 0 1 0 354.96 0 a177.48 177.48 0 1 0 -354.96 0z' fill='rgba(247%2c 177%2c 2%2c 0.397)' class='triangle-float3'%3e%3c/path%3e%3cpath d='M1179.45 342.85 a105.67 105.67 0 1 0 211.34 0 a105.67 105.67 0 1 0 -211.34 0z' fill='rgba(247%2c 177%2c 2%2c 0.397)' class='triangle-float2'%3e%3c/path%3e%3cpath d='M21.8 546.42 a186.56 186.56 0 1 0 373.12 0 a186.56 186.56 0 1 0 -373.12 0z' fill='rgba(247%2c 177%2c 2%2c 0.397)' class='triangle-float1'%3e%3c/path%3e%3cpath d='M464.04 1.54 a175.17 175.17 0 1 0 350.34 0 a175.17 175.17 0 1 0 -350.34 0z' fill='rgba(247%2c 177%2c 2%2c 0.397)' class='triangle-float2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1053'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3cstyle%3e %40keyframes float1 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(-10px%2c 0)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float1 %7b animation: float1 5s infinite%3b %7d %40keyframes float2 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(-5px%2c -5px)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float2 %7b animation: float2 4s infinite%3b %7d %40keyframes float3 %7b 0%25%7btransform: translate(0%2c 0)%7d 50%25%7btransform: translate(0%2c -10px)%7d 100%25%7btransform: translate(0%2c 0)%7d %7d .triangle-float3 %7b animation: float3 6s infinite%3b %7d %3c/style%3e%3c/defs%3e%3c/svg%3e");  

    .app-left {
        width: 100%;
        position: relative;
        z-index: 2;

        .app-info {
            width: 100%;
            background-color: white;
            border-radius: 10px 10px 0 0;
            padding: 15px;
            position: relative;
        
            p {
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0px;
                color: black;
            }

            p:last-child::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                height: 20px;
                width: 100%;
                background: url(./images/rip.svg) bottom center;
                background-size: 150%;
            }
            
        }

 
        .app-btns {
            display: flex;
            justify-content: space-around;
            align-items: center;

            img:nth-child(1) {
                width: 50%;
            }
            img:nth-child(2) {
                width: 20%;
            }
        }
    }


    .app-right {
        width: 100%;

        img {
            display: block;
            position: relative;
            width: 100%;
        }
    }
}









