#mn-mock {
    width: 100%;

    .header {
        img {
            width: 100%;
        };
    }

    .col-md-6 {
        display: flex;
       
        > div {
            width: 100%;
            background-color: rgb(255, 255, 255);
            border-radius: 10px;
            border: 2px solid rgb(238, 238, 238);
            transition: all 0.3s ease 0s;

            &:hover {
                box-shadow: rgba(54, 73, 144, 0.13) 0px 0px 32px 0px;
            }

            > svg {
                position: absolute;
                right: 20px;
                bottom: 20px;
                width: 100px;
                height: 100px;
            }
        }

        h4 {
            font-size: 26px;
            font-weight: 400;
            line-height: 44px;
            color: rgb(51, 51, 51);
        }

        p {
            width: 300px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px;
            color: rgb(118, 119, 124);
        }

        a {
            width: 120px;
            height: 40px;
            line-height: 40px;
            
            display: block;
            text-align: center;
            border-radius: 20px;
            cursor: pointer;
        }

       
    };

    .bottom {
        display: flex;
        flex-direction: row;
        border-radius: 10px;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(244, 248, 255);

        a {
            display: block;
            width: 120px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #232323;
            background-color: rgb(255, 255, 255);
            box-shadow: rgba(141, 180, 255, 25%) 0px 0px 10px 0px;
            border-radius: 20px;
            cursor: pointer;
        }
    }
}