#mn-modal-popup-material {
    z-index: 1000;
    .modal-content {
        background-color: transparent;
        border: transparent;
        position: relative;


        svg {
            position: absolute;
            left: auto;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            color: gray;
        }

        img {
            width: 100%;
        }
    }
   
}

