#mn-footer {
   background-color: #333333;
   color: white;
   padding-bottom: 80px;

   a {
       color: inherit;

        &:hover {
            color: var(--secondary-color);
            text-decoration: none;
        }
   }

   hr {
    border-top: 1px solid rgb(136, 136, 136);
   }

   .law {
       display: flex;
       justify-content: space-between;

       .policies {
           display: flex;
           flex-direction: row;
       }
   }

   p {
       color: gray;
   }

   .col-md-3:first-child {
       img {
           width: 80%;
       }
       p {
           color: white;
       }
   }

   svg { 
       width: 25%;
       height: 25%;
   }

  
}

@media only screen and (max-width: 768px) {
    #mn-footer {
        text-align: center;

        .law {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

}