#mn-promise {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: white;

    .item {
        display: grid;
        grid-template-columns: 1fr 4fr;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;

        svg {
            width: 100%;
            height: 100%;
        }

        .wrap {
            display: flex;
            flex-direction: column;


            p{
                margin: 0;
                font-size: 0.8rem;
            }
        }
    }
    .item:nth-child(odd) {
        background-color: #bf002e;
    }
    .item:nth-child(even) {
        background-color: #cf0233;
    }
}


@media only screen and (max-width:768px) {
    #mn-promise {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);

        .item {
            gap: 0;
            height: 120px;
            svg {
                width: 70%;
                height: 70%;
            }
        }
    }
}