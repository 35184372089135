#mn-course-section {

    .course-title {
        .up {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1.2vw;

            .title {
                font-weight: bold;
                font-size: 30px;
                display: inline-block;
            }
    
            .more {
                color: gray;
    
                &:hover {
                    cursor: pointer;
                }
                &.active {
                    color: var(--primary-color);
                }
            };
        }
        
        
    }

    .course-videos{
        .container {
            position: relative;

            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;

                .course-card {
                    overflow: hidden;
                    
                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        width: 100%;
                    }

                    .bottom {

    
                        p {
                            margin: 0
                        }
                    }
                    
                }
            }
            .swiper-pagination-bullet {
                &.swiper-pagination-bullet-active {
                    width: 20px;
                    height: 10px;
                    border-radius: 5px;
                    background: var(--primary-color);
                }
            }
    
            .swiper-button-prev-unique, 
            .swiper-button-next-unique
             {
                position: absolute;
                top: 50%;
                width: 50px;
                height: 50px;
                z-index: 99;
                border-radius: 50%;
                background-color: lightgray;
                text-align: center;
    
                svg {
                    width: 100%;
                    height: 100%;
                    fill: white;
    
                    &:hover {
                        fill: var(--primary-color);
                    }
                }
    
            }
            .swiper-button-prev-unique {
                left: 0;
                transform: translate(-100%, -50%)
            }
          
            .swiper-button-next-unique {
                right: 0;
                transform: translate(100%, -50%)
            }
        }

        
        
       
    }

}

@media only screen and (max-width: 768px) {
    #mn-course-section {
        font-size: 3vw;
        .course-title{
            .up {
                .title {
                    font-size: 5vw;
                }
            }
        }

        .course-videos{ 
            .course-card {
                .bottom {
                    padding: 1vw !important;
                    h5 {
                        font-size: 3vw;
                    }
                }
            }
        }
        
        .swiper-button-prev-unique, 
        .swiper-button-next-unique {
            display: none;
        }

    }
}