
// @use '../index';



#mn-fix-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
}

.mn-fix-footer-pc {
    color: white;
    width: 100vw;
    height: 80px;
    background-color: var(--primary-color);
    // background: index.$themeColor;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    /* you can't use img, or all other imgs will be affected */
    /* resize the img directly, it keep the aspect ratio if only specify width or height */
    .ad-img {
        width: 100px;
    }

    .ad-text {  
        font-size: large;
        text-align: center;
        letter-spacing: 2px;
    }
    .ad-text p {
        margin: 0;
    }

    .ad-close {
        width: 3vw;
        min-width: 15px;
    }
    
    .ad-form {
        background-color: white;
        border-radius: 0.5vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 40px;

       
        .form-icon {
            position: relative;
            left: 10px;

        }

        .form-input {
            padding: 10px;
        }
        .form-submit {
            display: block;
            flex-shrink: 0;
            height: 100%;
            background-color: #e42104;
            border-radius: 0px 0.5vw 0.5vw 0;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 2px;
            color: white;
            padding-left: 10px;
            padding-right: 10px;
            cursor: pointer;
        }
    }
} 
.mn-fix-footer-m {
    display: none;
}



@media only screen and (max-width:960px) {
    .mn-fix-footer-pc {
      display: none;
    }
  
    .mn-fix-footer-m  {
      display: block;
      width: 90%;
      max-width: 750px;
      margin: auto;

      .ad-bg {
        width: 100%;
      }
      
      .ad-close {
        position: absolute;
        top: 6vw;
        right: 2.5vw;
        width: 3vw;
        min-width: 15px;
      }
    }

}