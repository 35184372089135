#mn-platform {
    background: #f5f6ff;


    .practice-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
    }

    .practice-item {
        background-color: #ffffff;
        box-shadow: 0px 8px 8px 0px rgba(89, 91, 178, 0.04);
        border-radius: 8px;
        padding: 20px;
        width: 48%;
        position: relative;
        margin-bottom: 20px;
        transition: all 0.3s;
    }

    .practice-item:hover {
        box-shadow: 0px 12px 12px 0px rgba(89, 91, 178, 0.1);
        transform: translateY(-5px);
    }

    svg {
        padding: 0.7vw;
        border-radius: 100%;
        color: white;
    }

/* --- 练习平台 --- start */





.item-head h5 {
    display: inline-block;
    vertical-align: middle;
    line-height: 3.1vw;
    font-size: 1.25vw;
    letter-spacing: 0px;
    color: #333333;
    margin-left: 10px;
}

.practice-item p {
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    padding: 0 5px;
    margin-top: 15px;
}

.practice-item button {
    font-size: 18px;
    letter-spacing: 0px;
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    position: absolute;
    top: 30px;
    right: 30px;
}

.practice-item button img {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.practice-item button .static-icon {
    display: inline-block;
}

.practice-item button .hover-icon {
    display: none;
}

.practice-item:hover button .static-icon {
    display: none;
}

.practice-item:hover button .hover-icon {
    display: inline-block;
}

.practice-item:nth-child(1) button {
    background-color: rgba(89, 91, 178, 0.2);
    color: #595bb2;
}

.practice-item:nth-child(1):hover button {
    background-color: rgba(89, 91, 178, 1);
    color: #ffffff;
}

.practice-item:nth-child(2) button {
    background-color: rgba(26, 123, 249, 0.2);
    color: #1a7bf9;
}

.practice-item:nth-child(2):hover button {
    background-color: rgba(26, 123, 249, 1);
    color: #ffffff;
}

.practice-item:nth-child(3) button {
    background-color: rgba(11, 161, 215, 0.2);
    color: #0ba1d7;
}

.practice-item:nth-child(3):hover button {
    background-color: rgba(11, 161, 215, 1);
    color: #ffffff;
}

.practice-item:nth-child(4) button {
    background-color: rgba(255, 172, 76, 0.2);
    color: #ffac4c;
}

.practice-item:nth-child(4):hover button {
    background-color: rgba(255, 172, 76, 1);
    color: #ffffff;
}



}


@media only screen and (max-width:960px) {
    #mn-platform {
        .practice-item {
            width: 100vw;

            .item-head h5 {
                line-height: 50px;
                font-size: 20px;
            }
        }

        
    }
}