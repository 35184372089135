#mn-admin-nav {
    position: fixed;
    width: 100%;
    height: 80px;
    top: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dddee3;

    
    .header-left{
        img{
            width: 200px;
        }
    }

    .header-right{
        display: flex;
        justify-content: space-evenly;
        
        div {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        .invite {
            border-radius:10px;
            background-color: orange;
        }
        
        .vip {
            border-radius:10px;
            border: 2px solid var(--primary-color);

            svg {
                color: var(--primary-color);
            }
        }

        .avator {
            width: 36px;
            height: 36px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #mn-admin-nav {
        width: 100%;
        font-size: 2vw;

        .header-left{
            img{
                width: 30vw;
            }
        }
    }
}